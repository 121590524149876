import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const projectsData = [
  { id: 'My-3D-Printed-Projects', title: 'My 3D Printed Projects' }, 
  { id: 'ANDON-Visual-Light-Management-System', title: 'ANDON Visual Light Management System' },
  { id: 'Product-Line-Manufacturability', title: 'Product Line Manufacturability' },
  { id: 'ricksline-bar', title: 'Ricksline.Bar' },
  { id: 'Automatic-Plant-Watering-System', title: 'Automatic Plant Watering System' },
  { id: '4-Bar-Linkage-Mechanism', title: '4-Bar Linkage Mechanism' },
  { id: 'Robotic-Machine-Player', title: 'Robotic Machine Player' },
  { id: 'Paint-Shop-Tooling-Fixture', title: 'Paint Shop Tooling Fixture' },
  { id: 'Pencil-Holder', title: 'Pencil Holder' },
  { id: 'Fabricated-Rook-Chess-Piece', title: 'Fabricated Rook Chess Piece' },
  ,
];

function Layout({ children }) {
  const location = useLocation();
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsProjectsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsProjectsOpen(false);
  };

  const NavLink = ({ to, children, hasDropdown = false }) => {
    const isActive = location.pathname === to || (to === '/projects' && location.pathname.startsWith('/projects'));
    return (
      <Link 
        to={to} 
        className={`transition-colors duration-200 font-medium text-xl ${
          isActive 
            ? 'text-yellow-300 border-b-2 border-yellow-300' 
            : 'text-white hover:text-yellow-300'
        }`}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-primary text-text font-sans">
      <header className="bg-[#002147] shadow-lg">
        <div className="container mx-auto px-4">
          <button 
            className="md:hidden absolute right-4 top-4 text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>

          <div className={`${isMenuOpen ? 'block' : 'hidden'} md:block`}>
            <div className="flex flex-col md:flex-row md:justify-end py-3">
              <ul className="flex flex-col md:flex-row md:space-x-8 space-y-2 md:space-y-0">
                <li><NavLink to="/">Home</NavLink></li>
                <li 
                  className="relative group"
                  ref={dropdownRef}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavLink to="/projects" hasDropdown={true}>Projects</NavLink>
                  {isProjectsOpen && (
                    <div className="absolute left-0 md:top-full top-auto pt-2">
                      <ul className="bg-white rounded-md shadow-lg py-1 w-64">
                        {projectsData.map((project) => (
                          <li key={project.id}>
                            <Link 
                              to={`/projects/${project.id}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {project.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
                <li><NavLink to="/resume">Resume</NavLink></li>
                <li><NavLink to="/about">Experience</NavLink></li>
                <li><NavLink to="/contact">Contact</NavLink></li>
              </ul>
            </div>
          </div>

          <div className="py-4">
            <h1 className="text-4xl md:text-5xl font-bold text-white">Robert Daszynski</h1>
            <p className="text-lg md:text-xl text-yellow-200 mt-2">Mechanical Engineering Portfolio</p>
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        {children}
      </main>

      <footer className="bg-secondary py-6">
        <div className="container mx-auto px-4 text-center text-sm text-text-light">
          <p>&copy; 2024 Robert Daszynski. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
