import React from 'react';
import { Link } from 'react-router-dom';

const projectsData = [
  { 
    //My 3D Printed Projects Project Page Icon
    id: 'My-3D-Printed-Projects', 
    title: 'My 3D Printed Projects', 
    description: 'A collection of the 3D prints I have made',
    image: '/3d-prints/3d-prints-5.png',
    technologies: ['SolidWorks','Siemens NX', 'CreoCAD', 'Fusion 360', 'Onshape']
  },
  { 
    //ANDON Visual Light Management System Project Page Icon
    id: 'ANDON-Visual-Light-Management-System', 
    title: 'ANDON Visual Light Management System', 
    description: 'A comprehensive visual management system for manufacturing efficiency',
    image: '/andonPics/andon-9.jpg',
    technologies: ['Electrical Circuit Design', 'Soldering', 'SolidWorks', '3D Printing', 'Arduino', 'C++', 'JavaScript', 'Python']
  },
  { 
    //Product Line Manufacturability Project Page Icon
    id: 'Product-Line-Manufacturability', 
    title: 'Product Line Manufacturability', 
    description: 'Implemented a new product line onto the assembly floor and optimized for efficient manufacturing processes',
    image: '/plinePics/pline-1.png',
    technologies: ['DFA/DFM', 'Time Studies', 'Line Balancing', 'Value Stream Mapping', 'Tooling Design - SolidWorks', 'PFMEA', 'PPAP']
  },{ 
    //RicksLine.bar Page Icon
    id: 'ricksline-bar', 
    title: 'Ricksline.Bar', 
    description: "The ideal campus solution to viewing Michigan's most infamous bar line.",
    image: '/ricksPics/ricks2a.png',
    technologies: ["Entrepreneurial Thinking", "Project Management", "CAD", "3D Printing", "Circuit Design", "C++, React.js, Python", "Mechatronics", "Database Integration", "Optimization Algorithms", "Marketing"]
  },
  { 
    //Automatic Plant Watering System Project Page Icon
    id: 'Automatic-Plant-Watering-System', 
    title: 'Automatic Plant Watering System', 
    description: 'An arudino-based automatic plant watering system built for personal use',
    image: '/plantPics/plant-1.jpg',
    technologies: ['Arduino', 'C++', 'Circuit Design','Hardware Assembly']
  },{ 
    //4-Bar Linkage Mechanism Page Icon
    id: '4-Bar-Linkage-Mechanism', 
    title: '4-Bar Linkage Mechanism', 
    description: 'A class project involved designing and manufacturing a 4-bar linkage mechanism capable of sensing active targets and disabling them using an LED.',
    image: '/linkPics/link3.jpg',
    technologies: ['Solidworks', 'MSC ADAMS', 'Arduino', 'Proximity Sensor', 'Limit and Toggle Switch','C++ programming']
  },{ 
    //Robotic Machine Player Project Page Icon
    id: 'Robotic-Machine-Player', 
    title: 'Robotic Machine Player', 
    description: 'A class competition robot designed to complete objectives in an obstacle course',
    image: '/rmpPics/rmp-3.jpg',
    technologies: ['SolidWorks', 'GD&T', 'Rapid Prototyping', 'Soldering', '3D Printing', 'Fabrication', 'CAM']
    },{ 
    //Paint Shop Tooling Fixture Project Page Icon
    id: 'Paint-Shop-Tooling-Fixture', 
    title: 'Paint Shop Tooling Fixture', 
    description: 'A tooling fixture engineered to paint a heavy, unbalanced aerospace part',
    image: '/psfPics/psf-4.jpg',
    technologies: ['CreoCAD', '3D Printing', 'Fabrication']
  },{ 
    //Pencil Holder Project Page Icon
    id: 'Pencil-Holder', 
    title: 'Pencil Holder', 
    description: 'A pencil holder designed to hold pencils in an ergonomic and practical manner',
    image: '/pencilPics/pencil-3.jpg',
    technologies: ['SolidWorks', 'GD&T', 'Fabrication']
  },{ 
    //Fabricated Rook Chess Piece Project Page Icon
    id: 'Fabricated-Rook-Chess-Piece', 
    title: 'Fabricated Rook Chess Piece', 
    description: 'A custom-designed and fabricated chess piece showcasing precision manufacturing',
    image: '/rookPics/rook-3.jpg', 
    technologies: ['SolidWorks', 'GD&T', 'Fabrication', 'Mill/Lathe'] 
  },
  
];

function Projects() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">My Projects</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projectsData.map((project) => (
          <Link key={project.id} to={`/projects/${project.id}`} className="block">
            <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 flex flex-col h-full">
              <div className="relative pt-[75%] overflow-hidden">
                <img 
                  src={Array.isArray(project.image) ? project.image[0] : project.image} 
                  alt={project.title} 
                  className="absolute inset-0 w-full h-full object-contain"
                  onError={(e) => {
                    console.error(`Error loading image for ${project.title}:`, e);
                    e.target.src = 'https://via.placeholder.com/300x200?text=Image+Not+Found';
                  }}
                />
              </div>
              <div className="p-4 flex-grow">
                <h2 className="text-xl font-semibold mb-2">{project.title}</h2>
                <p className="text-gray-600 mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2">
                  {project.technologies.map((tech, index) => (
                    <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded text-sm">
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Projects;
